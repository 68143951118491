<template>
	<div v-html="content"></div>
</template>

<script>
import { detail } from '@/api/website/about'
export default {
	name: 'PcWebsiteIntro',

	data() {
		return {
			type: 2,
			content: ''
		};
	},

	mounted() {
		this.getinfo()
	},

	methods: {
		getinfo() {
			detail({ type: this.type }).then(res => {
				this.content = res.data.content
			})
		}
	},
};
</script>

<style lang="scss" scoped>

</style>